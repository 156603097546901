<template>
  <section class="la-step-handler">
    <app-loader type="primary" v-if="isLoading" />
    <div v-if="loanApplication && !isLoading">
      <article v-if="loanApplication.currentStep === 'A'">
        <pre-loan-data v-if="loanApplication.otpVerification" />
        <loan-application-otp-verification v-else />
      </article>

      <loan-application-simulator v-if="loanApplication.currentStep === 'C'" />

      <article
        class="merits-loop"
        v-for="(merit, index) in loanMerits.preLoan"
        :key="index"
      >
        <loan-application-merit
          v-if="loanApplication.currentStep === merit.metadata.stepId"
          :metadata="merit.metadata"
          :requirements="merit.requirements"
        />
      </article>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
// import { actions } from '@superpagos/mf-store';
import { AppLoader } from "@superpagos/mf-custom-components";
import LoanApplicationOtpVerification from "@/components/loan-application/loan-application-meritos/LoanApplicationOtpVerification";
import PreLoanData from "@/components/loan-application/loan-application-meritos/PreLoanData";
import LoanApplicationSimulator from "@/components/loan-application/loan-application-meritos/LoanApplicationSimulator";
import LoanApplicationMerit from "@/components/loan-application/loan-application-meritos/LoanApplicationMerit";

export default {
  name: "LoanApplicationStepHandler",
  components: {
    AppLoader,
    LoanApplicationOtpVerification,
    PreLoanData,
    LoanApplicationSimulator,
    LoanApplicationMerit,
  },
  data() {
    return {
      /* steps
       * I: Inicio <<< NUEVO
       * VI: Verificacion de identidad <<< NUEVO
       * P: Aplicación de políticas internas
       * VC: Verificación numero de celular <<< NUEVO
       * A: Activación de cupo
       * C: Calculadora
       * DP: Datos personales
       * DC: Datos de comercio
       * IF: Informacion financiera
       * SD: Subida de documentos
       * S: Preguntas de seguridad
       * F: Firma de documentos
       * */
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["loanApplication", "loanMerits"]),
  },
};
</script>
<style lang="scss">
.la-step-handler {
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem 0;
}
</style>
