<template>
  <div class="mf-app-container" id="mf-credits-app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'MfCreditsApp',
}
</script>
