<template>
  <section class="loan-application-status">
    <div style="text-align: center" v-if="loading">
      <app-loader type="primary" />
    </div>
    <div v-else>
      <loan-application-info
        v-if="!activation.state"
        :title="activation.title"
        :message="activation.message"
        message-type="error"
        action-label="Continuar"
        @click="setProcessRequirements"
      />

      <div v-else-if="loanApplication">
        <div v-if="loanApplication.success">
          <div
            class="loan-merit-status-checker"
            v-if="loanApplication.currentStep === 'P'"
          >
            <loan-application-info
              v-if="!loanApplication.policyStatus"
              title="Solicita tu Microcrédito"
              :message="`Tenemos cupos de ${moneyFormatter(
                loanApplication.policy.minAmount,
                0
              )} hasta ${moneyFormatter(loanApplication.policy.maxAmount, 0)}`"
              action-label="Verifica si aplicas"
              @click="submit"
              :loading="loading"
            />

            <loan-application-info
              v-if="loanApplication.policyStatus === 'A'"
              title="Tienes una solicitud en proceso"
              message="¿Deseas continuar con el proceso?"
              action-label="Continuar proceso"
              @click="continueProcess"
              :loading="loading"
            />

            <loan-application-info
              v-if="loanApplication.policyStatus === 'E'"
              title="Estamos estudiando tu solicitud"
              message="Algo no ha salido bien. Revisaremos como podemos ayudarte."
              message-type="error"
            />
          </div>

          <div
            class="loan-merit-status-checker"
            v-if="loanApplication.currentStep !== 'P'"
          >
            <loan-application-info
              v-if="loanApplication.maxAmountApproved"
              title="¡Felicidades!"
              message="Tienes un cupo de"
              message-type="success"
              :money-value="
                moneyFormatter(loanApplication.maxAmountApproved, 0)
              "
              action-label="Quiero utilizar mi cupo"
              @click="continueProcess"
            />

            <loan-application-info
              v-if="!loanApplication.maxAmountApproved"
              title="Tienes una solicitud en proceso"
              message="¿Deseas continuar con el proceso?"
              action-label="Continuar proceso"
              @click="continueProcess"
              :loading="loading"
            />
          </div>
        </div>

        <loan-application-info
          v-if="!loanApplication.success"
          title="Lo sentimos"
          :message="loanApplication.message"
          message-type="error"
        />
        <!--
        <loan-application-info
            title="Lo sentimos"
            message="No hemos podido validar tu identidad"
            message-type="error" />

        <loan-application-info
            title="Estamos estudiando tu solicitud"
            message="Algo no ha salido bien. Revisaremos como podemos ayudarte."
            message-type="error" /> -->
      </div>
    </div>
  </section>
</template>
<script>
import * as singleSpa from "single-spa";
import { mapGetters } from "vuex";
import { actions, mutations } from "@superpagos/mf-store";
import { AppLoader } from "@superpagos/mf-custom-components";
import { moneyFormat } from "@/utils/helpers";
import LoanApplicationInfo from "@/components/loan-application/LoanApplicationInfo";

export default {
  name: "LoanApplicationStatus",
  components: { AppLoader, LoanApplicationInfo },
  data() {
    return {
      moneyFormatter: moneyFormat,
      loading: true,
      activation: {
        state: null,
        title: "Activación requerida",
        message:
          "Necesitas completar algunos datos antes de solicitar un crédito.",
      },
      loanApplicationStatus: {
        kind: 'INIT', // String
      },
    };
  },
  computed: {
    ...mapGetters(["loanApplication", "loanMerits"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.activation.state = await this.checkActivationRequirementsStatus()
      this.loading = false
    },
    async submit() {
      await this.applyLoanPolicy();
    },
    async checkActivationRequirementsStatus() {
      const res = await this.$store.dispatch(
        actions.VALIDATE_PRODUCT_REQUIREMENTS,
        {
          categoryId: 152,
          productId: 136,
        }
      );
      return (this.activation.state = res.state);
    },
    async setProcessRequirements() {
      const path = "/requirement";
      await this.$store.commit(mutations.SET_REQUIREMENTS_PROCESS, {
        processRequirementsData: this.loanMerits.preLoanActivation,
      });
      singleSpa.navigateToUrl(path);
    },
    async applyLoanPolicy() {
      try {
        this.loading = true;
        await this.$store.dispatch(actions.APPLY_LOAN_POLICY);
        this.$router.push({ name: "loanApplication" });
      } catch (e) {
        console.log('applyLoanPolicy error', e)
        this.loanApplicationStatus.kind = "APPLY_LOAN_POLICY_ERROR";
      } finally {
        this.loading = false;
      }
    },
    async continueProcess() {
      this.$router.push({ name: "loanApplication" });
    },
  },
};
</script>
<style lang="scss">
.loan-application-status {
  display: flex;
  justify-content: center;

  /**
  * Desktop only
  */
  @media (min-width: 850px) {
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {
  }
}
</style>
