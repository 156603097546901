<template>
  <section class="la-otp-verification">
    <loan-application-header
        text-one="¡Ya casi tienes tu cupo!"
        text-two="Verifica tu número"
        text-three="Revisa tu Whatsapp y escribe el código que te enviamos en los recuadros de abajo" />
    <div class="la-otp-verification__container">
      <div>
        <app-otp-input v-model="otpModel"
                       :otp-error="otpError"
                       :otp-loading="loading"
                       @send-code="() => sendOtp" />
        <div class="la-otp-verification__actions">
          <div class="la-otp-verification__action-btn"
               :class="{ 'disabled': !otpFieldValidation || loading }"
               @click="validateOtp">Continuar</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { actions } from '@superpagos/mf-store';
import { AppOtpInput } from '@superpagos/mf-custom-components';
import LoanApplicationHeader from "@/components/loan-application/LoanApplicationHeader";

export default {
  name: 'LoanApplicationOtpVerification',
  components: { AppOtpInput, LoanApplicationHeader },
  data() {
    return {
      loading: false,
      otpModel: null,
      otpError: null,
      cellphone: null,
    }
  },
  computed: {
    otpFieldValidation() {
      return (this.otpModel && this.otpModel.length === 6)
    }
  },
  mounted() {
    const { cellphone } = this.$store.state.auth.user
    this.cellphone = cellphone
    this.sendOtp()
  },
  methods: {
    async sendOtp() {
      this.otpError = null
      await this.$store.dispatch(actions.LOAN_SEND_OTP, this.cellphone)
    },
    async validateOtp() {
      try {
        this.otpError = null
        this.loading = true
        await this.$store.dispatch(actions.LOAN_VALIDATE_OTP,
            { code: this.otpModel, cellphone: this.cellphone, })
      } catch (e) {
        console.log('error', e)
        this.otpError = e.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.la-otp-verification {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__actions {
    padding-top: 2rem;
    text-align: center;
  }

  &__action-btn {
    display: inline-block;
    padding: 0.5rem 2rem;
    border-radius: 40px;
    background-color: #00CEEE;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    // text
    font-size: 12px;
    color: white;

    &:hover {
      transform: scale(1.1);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>