<template>
  <section class="loan-application-simulator">
    <loan-application-header
      text-one="¡Ya casi!"
      text-two="Confirma tu cupo"
      text-three="Ajústalo según como lo necesites"
    />

    <div v-if="loadingDependencies" style="text-align: center">
      <app-loader type="primary" />
    </div>

    <div
      class="loan-application-simulator__row"
      v-if="loanApplication && !loadingDependencies"
    >
      <div class="loan-application-simulator__col">
        <div class="loan-application-simulator__title">Ajusta el valor</div>
        <div class="loan-application-simulator__range">
          <app-range-slider
            :min="loanApplication.policy.minAmount"
            :max="loanApplication.maxAmountApproved"
            v-model="form.loanValue"
            @blur="getLoanEstimate"
            @init="getLoanEstimate"
          />
        </div>

        <div class="loan-application-simulator__title">
          ¿A que plazo quieres pagarlo?
        </div>
        <div class="loan-application-simulator__subtitle">
          Selecciona el plazo para pagar tu microcrédito
        </div>

        <div class="loan-application-simulator__options">
          <div
            class="loan-application-simulator__option"
            v-for="timeLimit in conectame.timeLimits"
            :class="{ selected: timeLimit.id === form.timeLimit.id }"
            @click="setTimeLimit(timeLimit)"
            :key="timeLimit.id"
          >
            <div>{{ timeLimit.name }}</div>
          </div>
        </div>

        <div class="separator"></div>

        <div class="loan-application-simulator__title">Frecuencia</div>
        <div class="loan-application-simulator__subtitle">
          Selecciona la frecuencia de pago
        </div>

        <div class="loan-application-simulator__options">
          <div
            class="loan-application-simulator__option"
            v-for="frecuency in conectame.paymentFrequecies"
            :class="{
              selected: frecuency.id === form.paymentFrequecy.id,
              disabled: !checkFrecuencyById(frecuency.id),
            }"
            @click="setFrecuency(frecuency)"
            :key="frecuency.id"
          >
            <div>{{ frecuency.name }}</div>
          </div>
        </div>

        <div class="loan-application-simulator__info">
          <div class="loan-application-simulator__info_row">
            <div class="loan-application-simulator__info_col text-bold">
              Valor cuota {{ form.paymentFrequecy.name }}
            </div>
            <div class="loan-application-simulator__info_col">
              {{ moneyFormatter(loanEstimate.feeValue) }}
            </div>
          </div>
          <div class="loan-application-simulator__info_row">
            <div class="loan-application-simulator__info_col text-bold">
              Fecha de última cuota
            </div>
            <div class="loan-application-simulator__info_col">
              <div v-if="loanEstimate.paymentCalendar">
                {{
                  loanEstimate.paymentCalendar[
                    loanEstimate.paymentCalendar.length - 1
                  ].feeDueDate
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="loan-application-simulator__col">
        <div class="loan-application-simulator__image">
          <img :src="images.money" alt="" />
        </div>
        <div class="loan-application-simulator__title">Total a pagar</div>

        <div style="text-align: center" v-if="loadingEstimate">
          <app-loader type="primary" />
        </div>

        <div
          class="loan-application-simulator__total-amount"
          v-if="!loadingEstimate"
        >
          <span class="text-gradient">{{
            moneyFormatter(loanEstimate.totalToPay)
          }}</span>
        </div>

        <div class="pt-4" v-if="!loadingEstimate">
          <div
            class="flex justify-between text-[12px]"
            v-for="(item, index) in loanDetails"
            :key="index"
          >
            <div class="fw-bold">{{ item.label }}:</div>
            <div>{{ moneyFormatter(loanEstimate[item.key]) }}</div>
          </div>
        </div>

        <div
          class="loan-application-simulator__authorizations"
          v-if="!loadingEstimate"
        >
          <div class="loan-application-simulator__authorizations-item">
            <app-input type="checkbox" text="Aceptar" v-model="check.payment" />
            <span @click="showModal">plan de pagos</span>
          </div>
          <div class="loan-application-simulator__authorizations-item">
            <app-input
              type="checkbox"
              text="Autoriza utilizar únicamente la bolsa de Saldo"
              v-model="check.bag"
            />
          </div>
          <div class="loan-application-simulator__actions">
            <div
              class="loan-application-simulator__action-btn"
              :class="{ disabled: !check.payment || !check.bag }"
              @click="nextStep"
            >
              Continuar
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-modal
      id="loan-application-calendar-modal"
      ref="loan-application-calendar-modal"
    >
      <loan-application-payment-calendar
        :total-to-pay="moneyFormatter(loanEstimate.totalToPay)"
        :payments="loanEstimate.paymentCalendar"
      />
    </app-modal>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { actions } from "@superpagos/mf-store";
import {
  AppRangeSlider,
  AppInput,
  AppLoader,
  AppModal,
} from "@superpagos/mf-custom-components";
import LoanApplicationHeader from "@/components/loan-application/LoanApplicationHeader";
import { moneyFormat } from "@/utils/helpers";
import LoanApplicationPaymentCalendar from "@/components/loan-application/loan-application-meritos/LoanApplicationPaymentCalendar";

const FRECUENCY_DATA = {
  "timeLimitName": "30 Días",
  "frecuencyName": "Semanal",
  "totalInterestRate": 29.99,
  "feeInterestRate": 2.21,
  "requestedValue": 220000,
  "loanValue": 193820,
  "paymentOnSite": 26180,
  "totalToPay": 256277.52,
  "feeValue": 57524.38,
  "feesAmount": 4,
  "totalAdminValue": 4400,
  "totalAdminTaxValue": 836,
  "feeAdminValue": 1309,
  "feeAdminTaxValue": 209,
  "fullInsuranceValue": 0,
  "feeInsuranceValue": 0,
  "paymentCalendar": [{
    "feeNumber": 1,
    "feeDueDate": "08/11/2022",
    "feeValue": 113826.74,
    "feeCapital": 110000,
    "feeInterest": 1208.74,
    "feeAdminValue": 2618,
    "feeInsuranceValue": 0
  }, {
    "feeNumber": 2,
    "feeDueDate": "15/11/2022",
    "feeValue": 113826.74,
    "feeCapital": 110000,
    "feeInterest": 1208.74,
    "feeAdminValue": 2618,
    "feeInsuranceValue": 0
  }]
}

const TIME_LIMITS = [
  {
    "id": "1",
    "name": "4 meses",
    "timeLimit": "4"
  },
  {
    "id": "2",
    "name": "6 meses",
    "timeLimit": "6"
  },
  {
    "id": "3",
    "name": "8 meses",
    "timeLimit": "8"
  }
]

const PAYMENT_FRECUENCIES = [
  {
    "id": "3",
    "name": "Semanal"
  },
  {
    "id": "2",
    "name": "Quincenal"
  },
  {
    "id": "1",
    "name": "Mensual"
  }
]

const GET_ESTIMATE = {
  "1": {
    "timeLimitID": "1",
    "frecuencies": {
      "1": {
        "frecuencyID": "1",
        "data": [
          FRECUENCY_DATA
        ]
      },
      "2": {
        "frecuencyID": "2",
        "data": [
          FRECUENCY_DATA
        ]
      },
      "3": {
        "frecuencyID": "3",
        "data": [
          FRECUENCY_DATA
        ]
      }
    }
  },
  "2": {
    "timeLimitID": "2",
    "frecuencies": {
      "1": {
        "frecuencyID": "1",
        "data": [
          FRECUENCY_DATA
        ]
      },
      "2": {
        "frecuencyID": "2",
        "data": [
          FRECUENCY_DATA
        ]
      },
      "3": {
        "frecuencyID": "3",
        "data": [
          FRECUENCY_DATA
        ]
      }
    }
  },
  "3": {
    "timeLimitID": "3",
    "frecuencies": {
      "1": {
        "frecuencyID": "1",
        "data": [
          FRECUENCY_DATA
        ]
      },
      "2": {
        "frecuencyID": "2",
        "data": [
          FRECUENCY_DATA
        ]
      },
      "3": {
        "frecuencyID": "3",
        "data": [
          FRECUENCY_DATA
        ]
      }
    }
  }
}

export default {
  name: "LoanApplicationSimulator",
  components: {
    LoanApplicationHeader,
    AppRangeSlider,
    LoanApplicationPaymentCalendar,
    AppInput,
    AppLoader,
    AppModal,
  },
  computed: {
    ...mapGetters(["loanApplication"]),
  },
  data() {
    return {
      moneyFormatter: moneyFormat,
      loadingDependencies: true,
      loadingEstimate: true,
      images: {
        money: require("/public/images/money-icon.png"),
      },
      loanEstimate: {},
      loanEstimateComplete: {},
      form: {
        timeLimit: {},
        paymentFrequecy: {},
        loanValue: null,
      },
      conectame: {
        timeLimits: [],
        paymentFrequecies: [],
      },
      check: {
        payment: false,
        bag: false,
      },
      loanDetails: [
        { key: "loanValue", label: "Monto" },
        { key: "feeValue", label: "Interés" },
        { key: "totalAdminValue", label: "Administración" },
        { key: "paymentOnSite", label: "Aval" },
        { key: "totalAdminTaxValue", label: "IVA" },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // Get Timelimits && Get Payment frequencies
      await Promise.all([this.getTimeLimits(), this.getPaymentFrecuencies()]);
      this.loadingDependencies = false;
    },
    async getLoanEstimate() {
      this.loadingEstimate = true;
      const {
        timeLimit,
        paymentFrequecy,
        // loanValue
      } = this.form;
      /**
      const res = await this.$store.dispatch(actions.GET_PRE_LOAN_ESTIMATE, {
        loanValue: parseFloat(loanValue),
      });
      */
      const res = { data: {...GET_ESTIMATE} };
      // Store response to prevent multiple server calls
      this.loanEstimateComplete = res.data;
      // Set initial values
      this.loanEstimate =
        res.data[timeLimit.id].frecuencies[paymentFrequecy.id].data[0];
      this.loadingEstimate = false;
      console.log("LOAN", this.loanEstimate);
    },
    async getTimeLimits() {
      /**
       const res = await this.$store.dispatch(actions.GET_PRE_LOAN_TIME_LIMITS);
       */
      const res = { data: [...TIME_LIMITS] };
      this.form.timeLimit = res.data[0];
      this.conectame.timeLimits = res.data;
    },
    async getPaymentFrecuencies() {
      /**
       const res = await this.$store.dispatch(
        actions.GET_PRE_LOAN_PAYMENT_FRECUENCIES
      );
       */
      const res = { data: [...PAYMENT_FRECUENCIES] };
      this.form.paymentFrequecy = res.data[0];
      this.conectame.paymentFrequecies = res.data;
    },
    setTimeLimit(item) {
      this.form.timeLimit = item;
      let { paymentFrequecy } = this.form;

      // Validate if current payment frequency is accepted in selected time limit
      if (!this.checkFrecuencyById(paymentFrequecy.id)) {
        paymentFrequecy = this.conectame.paymentFrequecies[0];
        this.form.paymentFrequecy = paymentFrequecy;
      }

      this.loanEstimate =
        this.loanEstimateComplete[item.id].frecuencies[
          paymentFrequecy.id
        ].data[0];
    },
    setFrecuency(item) {
      this.form.paymentFrequecy = item;
      this.loanEstimate =
        this.loanEstimateComplete[this.form.timeLimit.id].frecuencies[
          item.id
        ].data[0];
    },
    // Validate if frecuency exist in current timelimit
    checkFrecuencyById(frecuencyId) {
      const { timeLimit } = this.form;
      const selectedTimelimit = this.loanEstimateComplete[timeLimit.id];
      return selectedTimelimit && selectedTimelimit.frecuencies[frecuencyId];
    },
    showModal() {
      this.$refs["loan-application-calendar-modal"].show();
    },
    hideModal() {
      this.$refs["loan-application-calendar-modal"].hide();
    },
    async nextStep() {
      this.loadingDependencies = true;
      const { loanValue, paymentFrequecy, timeLimit } = this.form;
      await this.$store.dispatch(actions.UPDATE_LOAN_APPLICATION_DATA, {
        formData: {
          loanValue,
          timeLimitID: timeLimit.id,
          frecuencyID: paymentFrequecy.id,
        },
        nextStep: "DP",
      });
    },
  },
};
</script>
<style lang="scss">
.loan-application-simulator {
  &__actions {
    padding-top: 2rem;
    text-align: center;
  }

  &__action-btn {
    display: inline-block;
    padding: 0.5rem 2rem;
    border-radius: 40px;
    background-color: #00ceee;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    // text
    font-size: 12px;
    color: white;

    &:hover {
      transform: scale(1.1);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .separator {
    height: 1.5rem;
  }

  .text-bold {
    font-weight: 600;
  }

  &__title {
    font-size: 15px;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 12px;
    color: #666466;
  }

  &__range {
    padding: 1.2rem 0;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    margin: 0 -0.5rem;
    padding-top: 1.2rem;
  }

  &__option {
    flex: auto;
    // text
    text-align: center;
    line-height: 1;
    font-size: 12px;
    color: #666466;

    &.selected {
      > div {
        border-color: #00ceee;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.25;
    }

    > div {
      margin: 0 0.5rem 0.5rem;
      padding: 0.5rem;
      border-radius: 8px;
      border: 2px solid #d8d8d8;
      background-color: #f8fafc;
      transition: border-color 0.35s ease-in-out;
      cursor: pointer;
    }
  }

  .loan-application-simulator__info {
    margin-top: 1.8rem;
    padding: 1.3rem 1.2rem;
    border-radius: 10px;
    background-color: #b0f4ff;
    // text
    line-height: 1.1;
    font-size: 15px;

    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;
    }
  }

  &__col:nth-of-type(2) {
    background-color: #f8fafc;
  }

  &__image {
    width: 55px;
    padding-bottom: 1.5rem;
  }

  &__total-amount {
    line-height: 1;
    font-size: 46px;
    font-weight: 800;
  }

  &__authorizations {
    padding-top: 1.6rem;
  }

  &__authorizations-item {
    padding-bottom: 5px;
    line-height: 1.2;

    &:nth-of-type(1) {
      display: flex;
      align-items: center;
    }

    > span {
      margin-top: 6px;
      margin-left: 5px;
      transition: opacity 0.35s ease-in-out;
      cursor: pointer;
      // text
      font-size: 14px;
      color: #0eb9ec;
    }

    .my-2 {
      margin: 0 !important;
      padding-bottom: 0 !important;
    }

    label.wrapper {
      margin-top: 0;
      // text
      font-size: 14px;
      font-weight: 400;

      input:checked ~ .checkmark {
        background-color: #00ceee;
      }

      .checkmark {
        width: 20px;
        height: 20px;
        background-color: #eaeaea;

        &:after {
          left: 7px;
          top: 2px;
          width: 7px;
          height: 13px;
        }
      }

      p.d-inline {
      }
    }
  }

  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__col:nth-of-type(1) {
      flex: 50%;
    }

    &__col:nth-of-type(2) {
      flex: 45%;
      margin-left: 5%;
      padding: 2rem;
      border-radius: 25px;
    }

    &__option {
      // text
      font-size: 14px;
    }
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {
  }
}
#loan-application-calendar-modal {
  /**
   * Desktop only
   */
  @media (min-width: 850px) {
    display: flex !important;
    align-items: center;

    .modal-content {
      height: auto;
    }
  }
}
</style>
