<template>
  <header class="loan-application-header">
    <div class="loan-application-header__line-1">{{ textOne }}</div>
    <div class="loan-application-header__line-2">
      <span class="text-gradient">{{ textTwo }}</span>
    </div>
    <div class="loan-application-header__line-3">{{ textThree }}</div>
  </header>
</template>
<script>
export default {
  name: 'LoanApplicationHeader',
  props: {
    textOne: { type: String },
    textTwo: { type: String },
    textThree: { type: String },
  }
}
</script>
<style lang="scss">
.loan-application-header {
  padding-bottom: 2rem;
  // text
  line-height: 1.2;
  text-align: center;

  &__line-1 {
    font-size: 20px;
    font-weight: 500;
  }
  &__line-2 {
    font-size: 30px;
    font-weight: 700;
  }
  &__line-3 {
    font-size: 15px;
    color: #666466;
  }

  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    padding-bottom: 3rem;
    &__line-1 {
      font-size: 1.05vw;
    }
    &__line-2 {
      font-size: 2.6vw;
    }
    &__line-3 {
      font-size: 0.8vw;
    }
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {
  }
}
</style>