<template>
  <div class="credits-layout">
    <!-- <button class="test-btn" @click="deleteLoanApplication">Borrar</button> -->
    <div style="text-align: center" v-if="loading">
      <app-loader type="primary" />
    </div>
    <div class="credits-page-container" v-if="!loading">
      <router-view />
    </div>
  </div>
</template>
<script>
import { actions } from "@superpagos/mf-store";
import { AppLoader } from "@superpagos/mf-custom-components";

export default {
  name: "MainLayout",
  components: { AppLoader },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        await Promise.all([this.getLoanMerits(), this.getLoanApplication()]);
        await this.getPersonValidationData();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async getLoanMerits() {
      await this.$store.dispatch(actions.GET_LOAN_APPLICATION_MERITS);
    },
    async getLoanApplication() {
      await this.$store.dispatch(actions.GET_LOAN_APPLICATION);
    },
    async getPersonValidationData() {
      await this.$store.dispatch(actions.FETCH_PERSON_VALIDATION);
    },
    async deleteLoanApplication() {
      // const res = await this.$store.dispatch(actions.DELETE_LOAN_APPLICATION)
      // this.$router.replace({name: 'credits'})
      // console.log('res', res)
    },
  },
};
</script>
<style lang="scss">
.credits-layout {
  padding: 2rem 1.5rem;
  background-color: white;

  .test-btn {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 10;
    padding: 5px 20px;
    border-radius: 12px;
    background-color: black;
    font-size: 12px;
    color: white;
  }

  .text-gradient {
    background: linear-gradient(90deg, #00ceee 0%, #722ae6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    padding: 2rem 6rem;
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {
  }
}
</style>
