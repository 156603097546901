<template>
  <section class="la-payment-calendar">
    <div class="la-payment-calendar__header">
      <div class="la-payment-calendar__header-title">Valor del cupo</div>
      <div class="la-payment-calendar__header-amount">
        <span class="text-gradient">{{ totalToPay }}</span>
      </div>
    </div>
    <div class="la-payment-calendar__row">
      <div class="la-payment-calendar__subtitle">Tu plan de pagos</div>
    </div>
    <div class="la-payment-calendar__table">
      <re-table :data="payments" :columns="columns" />
    </div>
  </section>
</template>
<script>
import ReTable from "@/components/utils/ReTable";
export default {
  name: 'LoanApplicationPaymentCalendar',
  components: { ReTable },
  props: {
    totalToPay: { type: String, required: true },
    payments: { type: Array, required: true },
  },
  data() {
    return {
      columns: [
        {
          name: 'plazo',
          label: 'Plazo',
          field: 'feeNumber',
          type: 'text',
          flex: '5%'
        },
        {
          name: 'date',
          label: 'Fecha',
          field: 'feeDueDate',
          type: 'text',
        },
        {
          name: 'amount',
          label: 'Valor cuota',
          field: 'feeValue',
          type: 'money',
        },
        {
          name: 'fee',
          label: 'Interés',
          field: 'feeInterest',
          type: 'money',
        },
        {
          name: 'adminFee',
          label: 'Admin + Tecnología',
          field: 'feeAdminValue',
          type: 'money',
          flex: '15%',
        },
        {
          name: 'insurance',
          label: 'Seguro',
          field: 'feeInsuranceValue',
          type: 'money',
        },
        {
          name: 'capital',
          label: 'Capital',
          field: 'feeCapital',
          type: 'money',
        },
        {
          name: 'balance',
          label: 'Saldo',
          field: 'loanBalance',
          type: 'money',
        },
      ]
    }
  }
}
</script>
<style lang="scss">
.la-payment-calendar {
  &__header {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #EFEFF0;
    // text
    line-height: 1;

    &-title {
      padding-bottom: 5px;
      // tetx
      font-size: 18px;
      font-weight: 700;
    }
    &-amount {
      font-size: 40px;
      font-weight: 800;
    }
  }

  &__subtitle {
    padding-bottom: 1.5rem;
    // text
    font-size: 15px;
    font-weight: 600;
  }

  /**
   * Desktop only
   */
  @media (min-width: 850px) {
    width: 80vw;
    max-width: 1200px;

    .la-payment-calendar__table {
      max-height: 400px;
      height: auto;
      overflow-y: auto;
    }
  }

  /**
   * Mobile only
   */
  @media (max-width: 850px) {
    width: 100%;
  }
}

.text-gradient {
  background: linear-gradient(90deg, #00CEEE 0%, #722AE6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
</style>