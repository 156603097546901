<template>
  <section class="loan-application-info">
    <div class="loan-application-info__group">
      <div class="loan-application-info__image">
        <img :src="images[messageType]" :alt="messageType" />
      </div>
    </div>
    <div class="loan-application-info__group">
      <div class="loan-application-info__title">{{ title }}</div>
      <div class="loan-application-info__message" v-if="message">
        {{ message }}
      </div>
      <div class="loan-application-info__money text-gradient" v-if="moneyValue">
        {{ moneyValue }}
      </div>
      <div class="loan-application-info__action">
        <app-loader type="primary" v-if="loading" />
        <div
          class="loan-application-info__action_btn"
          v-if="actionLabel && !loading"
          @click="onClick"
        >
          {{ actionLabel }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { AppLoader } from "@superpagos/mf-custom-components";
export default {
  name: "LoanApplicationInfo",
  components: { AppLoader },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
    moneyValue: {
      type: String,
    },
    actionLabel: {
      type: String,
    },
    messageType: {
      type: String,
      default: "info",
      validator(value) {
        return ["info", "success", "error"].includes(value);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: {
        info: require("/public/images/info-img.png"),
        success: require("/public/images/success-img.png"),
        error: require("/public/images/error-img.png"),
      },
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss">
.loan-application-info {
  margin: 0 auto;

  &__title {
    padding-bottom: 1rem;
    // text
    line-height: 1;
    font-size: 25px;
    font-weight: 700;
  }

  &__message {
    line-height: 1.2;
    font-size: 15px;
  }

  &__money {
    font-weight: 800;
    font-size: 50px;
  }

  &__action {
    padding-top: 1rem;

    &_btn {
      display: inline-block;
      padding: 5px 25px;
      border-radius: 30px;
      background-color: #00ceee;
      font-size: 15px;
      color: white;
    }
  }

  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43vw;

    img {
      width: 100%;
    }

    &__group:nth-of-type(1) {
      flex: 52%;
    }

    &__group:nth-of-type(2) {
      flex: 48%;
    }

    &__title {
      padding-bottom: 1.5rem;
      font-size: 2.6vw;
    }

    &__message {
      font-size: 1.54vw;
    }

    &__money {
      font-size: 4vw;
    }

    &__action {
      &_btn {
        transition: transform 0.35s ease-in-out;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {
    padding: 2rem 0;
    text-align: center;

    &__image {
      padding: 0 4rem;
      transform: translate(8%, 0);
    }
  }
}
</style>
