<template>
  <section class="la-security-questions">
    <div class="la-security-questions__wrap">
      <div class="la-security-questions__field" v-for="(field, key) in questions" :key="key">
        <div class="la-security-questions__field-question">{{ field.question }}</div>
        <div class="la-security-questions__field-option" v-for="(option, optionKey) in field.options" :key="optionKey">
          <input type="radio" :id="`${field.index}option${optionKey}`"
                 :value="{ index: field.index, value: option}"
                 v-model="form[`question${field.index}`]">
          <label :for="`${field.index}option${optionKey}`"> {{ option }}</label>
        </div>
      </div>
    </div>

    <app-button title="Validar respuestas"
                extraClass="primary-gradient"
                clase="primary mb-10 md:mb-20 px-5 w-full"
                :isLoading="isLoading"
                @click="validate"/>
  </section>
</template>
<script>
import {actions} from '@superpagos/mf-store';
import {AppButton} from '@superpagos/mf-custom-components';

export default {
  name: 'LoanApplicationSecurityQuestions',
  components: { AppButton },
  props: {
    nextStep: { type: String, required: true }
  },
  data() {
    return {
      isLoading: true,
      questions: null,
      error: {
        kind: null,
      },
      form: {}
    }
  },
  mounted() {
    this.getSecurityQuestions()
  },
  methods: {
    async getSecurityQuestions() {
      try {
        this.isLoading = true
        this.error = { kind: null }
        const questions = await this.$store.dispatch(actions.GET_LOAN_SECURITY_QUESTIONS)
        const { status, message, data } = questions

        console.log('questions response', questions)

        switch (status) {
          case 'VALIDATE':
            this.questions = data;
            break;
          case 'OK':
            await this.goToNextStep();
            break;
          default:
            this.error = { kind: 'ERROR', message }
            break;
        }
      } catch (e) {
        console.log('error', e)
      } finally {
        this.isLoading = false
      }
    },
    async validate() {
      try {
        this.isLoading = true
        /**
        let responses = Object.keys(this.form).map((key) => this.form[key])
        const res = await this.$store.dispatch(actions.VALIDATE_LOAN_SECURITY_QUESTIONS, responses)
        const { payload, success } = res
         */
        /*
        * res.payload =
        * error: "information_mismatch_end"
        * message: "no fue posible validar la identidad"
        * status: "ERROR"
        **/
        /**
        if (!success) {
          return console.log('Error:', payload.message)
        }
         */

        await this.goToNextStep()
      } catch (e) {
        console.log('Ups error here:', e)
      } finally {
        this.isLoading = false
      }
    },
    async goToNextStep() {
      await this.$store.dispatch(actions.UPDATE_LOAN_APPLICATION, {
        currentStep: this.nextStep
      })
    }
  }
}
</script>
<style lang="scss">
.la-security-questions {
  &__field {
    padding-bottom: 2rem;
    font-size: 14px;
  }

  &__field-question {
    padding-bottom: 1.5rem;
    font-weight: 600;
    font-size: 16px;
  }

  &__field-option {
    font-weight: 400;

    label {
      margin-left: 10px;
    }
  }
}
</style>
