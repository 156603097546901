<template>
  <div class="credits-page-home">
    <div class="credits-page-home-wrap">
      <loan-application-status />
      <loan-list v-if="loanListData" />
      <!-- <mati-button
          clientid="62042748d1f8ef001df20f39"
          flowId="6328d25d7a48df001c393baf"
          :metadata="JSON.stringify(metadataTest)"
      /> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LoanList from "@/components/loan/LoanList";
import LoanApplicationStatus from "@/components/loan-application/LoanApplicationStatus";

export default {
  name: 'index-view',
  components: {LoanList, LoanApplicationStatus,},
  data() {
    return {
      loanListData: null,
      metadataTest: {
        "applicationId": 129,
        "sadminUuid": 1234,
        "amount": 700000
      }
    }
  },
  computed: {
    ...mapGetters(["loanApplication"]),
  },
}
</script>
<style lang="scss">
.credits-page-home {
  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(90vh - 90px);
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {}
}
</style>