<template>
  <section class="pre-loan-data">
    <div
      class="pre-loan-data__content"
      v-if="initalLoading && !loanApplication.loanUuid"
    >
      <loan-application-header
        text-one="¡Ya casi!"
        text-two="Confirma tus datos"
        text-three="Estás a un clic de activarlo"
      />
      <div class="pre-loan-data__form">
        <div class="pre-loan-data__row">
          <div class="pre-loan-data__column">
            <app-input
              label="Tipo de documento"
              disabled="true"
              v-model="form.documentType"
            />
          </div>
          <div class="pre-loan-data__column">
            <app-input
              label="Número de documento"
              v-model="form.documentNumber"
            />
          </div>
          <div class="pre-loan-data__column">
            <app-input label="Primer Apellido" v-model="form.lastNames" />
          </div>
          <div class="pre-loan-data__column">
            <app-input label="Celular" v-model="form.cellPhoneNumber" />
          </div>
        </div>
        <div class="terms-agreement pt-4 d-flex">
          <label class="d-flex">
            <input
              class="checkbox mr-0 ml-2"
              type="checkbox"
              value="1"
              v-model="form.terms"
            />
          </label>
          <span class="text-sm text-left ml-2" @click="showAgreement">
            Tratamiento de datos y Consulta - Reporte centrales
          </span>
        </div>
        <div class="pre-loan-data__actions">
          <app-loader type="primary" v-if="loading" />
          <div
            class="pre-loan-data__action-btn"
            v-if="!loading"
            :class="{ disabled: fieldValidation || loading }"
            @click="submit"
          >
            Continuar
          </div>
        </div>
      </div>

      <app-modal id="dataProcessingAgreement">
        <section>
          <h3 class="pb-5 fw-bold text-center">
            Tratamiento de datos y Consulta - Reporte centrales
          </h3>
          <p class="text-[14px]">
            {{ loanApplication.policy.dataProcessingAgreement }}
          </p>
          <div class="py-[1rem]"></div>
          <div class="pre-loan-data__action-btn" @click="hideAgreement">
            Cerrar
          </div>
        </section>
      </app-modal>
    </div>

    <loan-application-info
      v-if="initalLoading && loanApplication.loanUuid"
      title="¡Felicidades!"
      message="Tienes un cupo de"
      message-type="success"
      :money-value="moneyFormatter(loanApplication.maxAmountApproved, 0)"
      action-label="Quiero utilizar mi cupo"
      @click="useLoan"
    />
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { actions, mutations } from "@superpagos/mf-store";
import { moneyFormat } from "@/utils/helpers";
import {
  AppInput,
  AppLoader,
  AppModal,
} from "@superpagos/mf-custom-components";
import LoanApplicationHeader from "@/components/loan-application/LoanApplicationHeader";
import LoanApplicationInfo from "@/components/loan-application/LoanApplicationInfo";

export default {
  name: "PreLoanData",
  components: {
    AppInput,
    AppLoader,
    AppModal,
    LoanApplicationHeader,
    LoanApplicationInfo,
  },
  data() {
    return {
      moneyFormatter: moneyFormat,
      success: false,
      initalLoading: false,
      loading: false,
      form: {
        documentType: "Cédula de ciudadanía",
        documentNumber: null,
        lastNames: null,
        cellPhoneNumber: null,
        terms: false,
      },
    };
  },
  computed: {
    ...mapGetters(["user", "loanApplication"]),
    fieldValidation() {
      return (
        !this.form.documentType ||
        !this.form.documentNumber ||
        !this.form.lastNames ||
        !this.form.cellPhoneNumber ||
        !this.form.terms
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.form = {
        documentType: "Cédula de ciudadanía",
        documentNumber: null,
        lastNames: this.user.lastName,
        cellPhoneNumber: this.user.cellphone,
      };
      this.initalLoading = true;
    }, 1000);
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await this.$store.dispatch(actions.GET_PRE_LOAN_DATA, this.form);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    useLoan() {
      this.$store.commit(mutations.SET_LOAN_APPLICATION, {
        ...this.loanApplication,
        currentStep: "C",
      });
    },
    showAgreement() {
      this.$bvModal.show("dataProcessingAgreement");
    },
    hideAgreement() {
      this.$bvModal.hide("dataProcessingAgreement");
    },
  },
};
</script>
<style lang="scss">
.pre-loan-data {
  &__actions {
    padding-top: 2rem;
    text-align: center;
  }

  &__action-btn {
    display: inline-block;
    padding: 0.5rem 2rem;
    border-radius: 40px;
    background-color: #00ceee;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    // text
    font-size: 12px;
    color: white;

    &:hover {
      transform: scale(1.1);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .terms-agreement {
    span {
      cursor: pointer;
      color: #00ceee;
    }
  }
  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    &__form {
      width: 40vw;
      margin: 0 auto;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__column {
      flex: 50%;
      padding: 0 1vw;

      .content-select-provider {
        padding-bottom: 0;
      }

      .text-lg {
        font-size: 15px !important;
      }

      input {
        background-color: #efeff0;
      }
    }
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {
  }
}
</style>
