<template>
  <section class="loan-application-page">
    <loan-application-info
        v-if="!loanApplication.success"
        title="Lo sentimos"
        :message="loanApplication.message"
        message-type="error" />

    <loan-applicacion-step-handler v-else />
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import LoanApplicacionStepHandler from "@/components/loan-application/LoanApplicacionStepHandler";
import LoanApplicationInfo from "@/components/loan-application/LoanApplicationInfo";
export default {
  name: 'LoanApplicationStatus',
  components: { LoanApplicacionStepHandler, LoanApplicationInfo },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["loanApplication"]),
  },
}
</script>
<style lang="scss">
.loan-application-page {

  /**
  * Desktop only
  */
  @media (min-width: 850px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(90vh - 90px);
  }

  /**
  * Mobile only
  */
  @media (max-width: 850px) {}
}
</style>