const moneyFormat = (input, decimals) => {

    // Check input data type
    const _typeOf = typeof input;
    if (_typeOf === 'object' || _typeOf === 'function' || _typeOf === 'symbol' || _typeOf === 'boolean')
        return 'Formato no soportado';

    decimals = (typeof decimals === 'string') ? parseInt(decimals) : decimals

    // Create formater
    const _decimals = 2;
    const options = {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: decimals !== null ? decimals : _decimals,
        maximumFractionDigits: decimals !== null ? decimals : _decimals,
    };
    const _currency = new Intl.NumberFormat('es-CO', options);

    // Apply formatter
    input = (_typeOf === 'string') ? parseFloat(input.replace(/\D+/g, '')) : input

    return _currency.format(input)
}

export { moneyFormat }