<template>
  <div class="text-center flex flex-center">
    <div>
      <div style="font-size: 30vh">
        404
      </div>

      <div style="opacity:.4">
        Oops. No hay nada aquí...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404-view',
}
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
</style>
