import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
// Components
import vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';
import store from '@superpagos/mf-store';
import services, { MixinService } from '@superpagos/mf-services';
import router from './router';

import {
  AppLoader,
  AppInput,
  AppButton,
  AppModal,
} from '@superpagos/mf-custom-components';

Vue.use(vuex);
Vue.use(BootstrapVue);

// Init Store
services.ApiServiceAuthorizer.init(store);
services.ApiServiceRequirement.init(store);
services.ApiServiceCredits.init(store);

Vue.mixin({ methods: MixinService });

// Components
Vue.component('app-loader', AppLoader);
Vue.component('app-input', AppInput);
Vue.component('app-button', AppButton);
Vue.component('app-modal', AppModal);

let Store = store;

// User Guards
Vue.directive('can', {
  bind(el, binding) {
    let user = Store.state.auth.user;
    if (user.Rol.permissions.indexOf(binding.value) == -1) {
      el.style.display = 'none';
    }
  },
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    store,
    render: h => h(App),
    router,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
