<template>
  <section class="re-table-row" :class="[ 're-table-row-' + index ]">
    <div class="re-table-row__column"
         v-for="(col, index) in columns"
         :key="index" :style="{ flex: col.flex || `${100/columns.length}%` }">
      <div class="re-table-row__column-label">{{ col.label }}</div>
      <div class="re-table-row__column-value" v-if="col.type === 'money'">{{ moneyFormatter(row[col.field]) }}</div>
      <div class="re-table-row__column-value" v-else>{{ row[col.field] }}</div>
    </div>
  </section>
</template>
<script>
import { moneyFormat } from "@/utils/helpers";
export default {
  name: 'ReTable',
  props: {
    index: { type: Number, required: true },
    row: { type: Object, required: true },
    columns: { type: Array, required: true }
  },
  data() {
    return {
      moneyFormatter: moneyFormat
    }
  },
}
</script>
<style lang="scss" scoped>
.re-table-row {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  &__column {
    padding: 2px 0;
    font-size: 12px;
    line-height: 1.2;

    &-label {
      font-weight: 600;
    }
  }

  /**
   * Desktop only
   */
  @media (min-width: 850px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;

    &-0 {
      margin-top: 1rem;
      .re-table-row__column {
        position: relative;
        &-label {
          display: block;
          visibility: visible;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0 1rem;
          transform: translate(0, -30px);
          font-weight: normal;
        }
      }
    }

    &__column {
      display: flex;
      align-items: center;
      padding: 0 1rem;

      &-label {
        display: none;
        visibility: hidden;
      }
    }
  }

  /**
   * Mobile only
   */
  @media (max-width: 850px) {
    &__column {
      display: flex;

      &-label {
        flex: 50%;
        margin-right: 20px;
      }
      &-value {
        flex: 50%;
      }
    }
  }
}
</style>