import MainLayout from "@/layout/MainLayout";
import Error404 from '@/views/Error404.vue';
import IndexView from '@/views/Index.vue';
// import SoonView from '@/views/Soon.vue';
import LoanApplicationView from '@/views/LoanApplicationView';

const routes = [
    /*{
        path: '/',
        component: SoonView,
    },*/
    {
        path: '/', // path: '/application',
        component: MainLayout,
        children: [
            { path: '', name: 'credits', component: IndexView, },
            { path: 'loan-application', name: 'loanApplication', component: LoanApplicationView, },
        ],
    },

    // Show error 404 if page not found
    { path: '/:catchAll(.*)*', component: Error404, }
]

export default routes
