<template>
  <section class="la-data">
    <div class="la-data__content">
      <loan-application-header
          :text-one="metadata.header.t1"
          :text-two="metadata.header.t2"
          :text-three="metadata.header.t3" />
      <div class="grid grid-cols-12 md:mx-20 md:gap-x-4 mb-[80px]">
        <div class="col-span-12 md:col-span-6"
             v-for="field in requirements"
             :key="field.name">
          <app-meta-map
              v-if="field.form.type == 'metamap'"
              class="col-span-12 md:!col-span-12"
              :field="field"
          ></app-meta-map>
          <loan-application-security-questions
              :next-step="metadata.nextStep"
              v-else-if="field.form.type == 'securityQuestions'" />
          <to-do-input
              v-else
              :field="field"
              @value="(value) => setFieldValue(value, field)"
          ></to-do-input>
        </div>
      </div>
      <div class="md:flex md:justify-center col-span-12 mt-10 mb-20 flex justify-center">
        <app-button
            v-if="metadata.nextStep"
            title="Continuar"
            extraClass="primary-gradient"
            clase="primary mb-10 md:mb-20 px-5 w-full"
            :isLoading="isLoading"
            @click="onSubmit"
        ></app-button>
        <app-button />
      </div>
    </div>
  </section>
</template>
<script>
import {mapGetters} from 'vuex';
import {actions} from '@superpagos/mf-store';
import {AppButton} from '@superpagos/mf-custom-components';
import {ToDoInput, AppMetaMap} from '@superpagos/mf-navbar';
import LoanApplicationHeader from "@/components/loan-application/LoanApplicationHeader";
import LoanApplicationSecurityQuestions
  from "@/components/loan-application/loan-application-meritos/LoanApplicationSecurityQuestions";

export default {
  name: 'LoanApplicationMerit',
  components: {
    LoanApplicationHeader,
    AppButton,
    ToDoInput,
    AppMetaMap,
    LoanApplicationSecurityQuestions,
  },
  props: {
    metadata: { type: Object, required: true },
    requirements: { type: Array, required: true },
  },
  data() {
    return {
      isLoading: false,
      formData: {},
      files: [],
      errors: [],
    }
  },
  computed: {
    ...mapGetters(["loanApplicationData", "user"]),
  },
  mounted() {
    // this.getLoanApplicationData()
  },
  methods: {
    async getLoanApplicationData() {
      await this.$store.dispatch(actions.GET_LOAN_APPLICATION_DATA)
    },
    setFieldValue(value, field) {
      this.formData[`${field.id}`] = value
    },
    // TODO: Handle file upload
    async handlerFile() {},
    async onSubmit () {
      this.isLoading = true
      const { name, lastname } = this.user

      await this.$store.dispatch(actions.UPDATE_LOAN_APPLICATION_DATA, {
        formData: { ...this.formData, firstName: name, surName: lastname },
        nextStep: this.metadata.nextStep
      })

      this.isLoading = false
    },
  }
}
</script>
<style lang="scss">
.la-data {

  /**
  * Desktop only
  */
  @media (min-width: 850px) {}

  /**
  * Mobile only
  */
  @media (max-width: 850px) {}
}
</style>
