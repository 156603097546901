<template>
  <section class="re-table">
    <div v-for="(row, index) in data" :key="row.name">
      <re-table-row :row="row" :columns="columns" :index="index" />
    </div>
  </section>
</template>
<script>
import ReTableRow from "@/components/utils/ReTableRow";
export default {
  name: 'ReTable',
  components: { ReTableRow },
  props: {
    columns: { type: Array, required: true },
    data: { type: Array, required: true },
  },
}
</script>
<style lang="scss" scoped>
.re-table {
  /**
   * Desktop only
   */
  @media (min-width: 850px) {
    padding: 2rem 0;
  }
}
</style>